import { inject } from '@angular/core';
import type { ResolveFn } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { SafeTkTableColumn } from 'src/app/safetk-table/components/safetk-table/safetk-table.component';
import { STIL_LanguageTranslationDataService } from '~shared/services/apiSTILLanguageTranslationController';
import { TranslationsState } from '~translations/state/translations.state';

export type TranslationsData = {
  columns: SafeTkTableColumn[];
  translations: any[];
};

export const translationsDataResolver: ResolveFn<TranslationsData> = async (route, state) => {

  const translationState = inject(TranslationsState);

  const translationSvc = inject(STIL_LanguageTranslationDataService);

  const translations = await firstValueFrom(translationSvc.getRecordsForParentIDWithoutCache(translationState.get('activeLanguage').ID));

  const columns: SafeTkTableColumn[] = [
    {
      label: 'Translation Type',
      name: 'itemType',
      editable: true,
      sortable: true,
      template: 'combobox',
      config: {
        controlType: 'combobox',
        filter: true,
        options: translations,
        referenceProp: 'itemType'
      },
      order: 1
    },
    {
      label: 'Feature Name',
      name: 'formName',
      editable: true,
      sortable: true,
      template: 'combobox',
      config: {
        controlType: 'combobox',
        filter: true,
        options: translations,
        referenceProp: 'formName'
      },
      order: 2
    },
    {
      label: 'Translation Path',
      name: 'controlName',
      editable: true,
      sortable: true,
      template: 'combobox',
      config: {
        controlType: 'combobox',
        filter: true,
        options: translations,
        referenceProp: 'controlName'
      },
      order: 3
    },
    {
      label: 'Translation Key',
      name: 'defaultCaption',
      editable: true,
      sortable: true,
      config: {
        controlType: 'combobox',
        filter: true,
        options: translations,
        referenceProp: 'defaultCaption'
      },
      order: 4
    },
    {
      label: 'Translated Value',
      name: 'preferredCaption',
      editable: true,
      sortable: true,
      template: 'combobox',
      config: {
        controlType: 'combobox',
        filter: true,
        options: translations,
        referenceProp: 'preferredCaption'
      },
      order: 5
    }
  ];

  return {
    columns,
    translations
  };
};
