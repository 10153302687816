import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  SimpleChanges
} from '@angular/core';
import { Dictionary } from '~shared/models/dictionary';
import { SafeTKDB } from '~indexedDB';
import { FacilityState } from '~core/states/facility/facility.state';
import { NetworkState } from '~offline/states/network-state/network.state';
import { filter } from 'lodash';

@Component({
  selector: 'app-nav-group',
  templateUrl: './nav-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavGroupComponent implements  OnChanges {

  private cdr = inject(ChangeDetectorRef);
  private facilityState = inject(FacilityState);
  private networkState = inject(NetworkState);
  private indexedDB = inject(SafeTKDB);

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.group) {
      const permits = await this.indexedDB.db.permits
        .where('tableName')
        .equals(this.group.itemType)
        .toArray();

      this.hasOfflinePermits = !!permits?.length;

      this.networkState.isOffline$.subscribe(isOffline => {
        if (isOffline) {
          this.links.set(filter(this.group?.links, link => !(link.name.startsWith('Permit Index') || link.name.startsWith('Standard Index'))));
        } else {
          this.links.set(this.group?.links);
        }
      });

      this.cdr.markForCheck();
    }
  }

  @Input() group: Dictionary<any>;

  @Input() isActive: boolean;

  @Input() isOpen: boolean;

  @Output() groupClick = new EventEmitter<Dictionary<any>>();

  hasOfflinePermits = false;

  links = signal([]);

  get offlineModeEnabled(): boolean {
    return !!this.facilityState.get('moduleSettings')?.OfflineMode;
  }

}
